import React from "react"
import "../components/layout.css"
import { useStateIfMounted } from "use-state-if-mounted"
import NavigationBar from "../components/HeaderComponents/NavigationBar"
import { MainPageJson, ServiceJson, PathJson } from "../components/location"
import SingaporeOne from "../components/SingaporeLanding/SingaporeOne"
import SingaporeTwo from "../components/SingaporeLanding/SingaporeTwo"


function SingaporeLanding() {
  const MainPageContent = MainPageJson()
  const PathContent = PathJson()
  const [statusNav, setStatusNav] = useStateIfMounted(false)

  const statusNavbar = status => {
    setStatusNav(status)
  }
  return (
    <>
      <NavigationBar
        props={MainPageContent.Header}
        propsLink={PathContent}
        parentCallback={statusNavbar}
        disableNav={true}
      />
      {/* <SingaporeOne status={statusNav}/> */}
      <SingaporeTwo status={statusNav}/>
    </>
  )
}

export default SingaporeLanding
