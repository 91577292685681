import React, { useState } from "react"
import axios from "axios"
import { Fade } from "react-reveal"
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import fbLogo from "../../images/socmed-fb-new.png"
import igLogo from "../../images/socmed-ig-new.png"
import linkedInLogo from "../../images/socmed-linkedin-new.png"
import ytLogo from "../../images/socmed-yt-new.png"
import singLanding1 from "../../images/Singapore-Landing-1.png"
import singRes2 from "../../images/Singapore-Res-2.png"
import baloonSgd from "../../images/Baloon-SGD.gif"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCheck,
  faTimesCircle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons"

import {
  TitleDiv,
  SinTitle,
  SinDesc,
  ResponsiveRow,
  FormCol,
  FormControl,
  Break,
  ButtonYellow,
  LogoSocmed,
  FormDiv,
  RightCol,
  ImageDiv,
  ReleaseCol,
  TncDiv,
  SocmedDiv,
  ImageSin,
  ImageSinRes,
  FreeBr,
  AlertDiv,
  AlertDivRes,
  LinkTnc,
  ImgGif,
  ImgGifRes,
} from "./StyleOne"

function SingaporeOne({ status }) {
  // singapore-B
  const socmedDatas = [
    {
      name: "Instagram",
      link: "https://www.instagram.com/transfez.id/",
      logo: igLogo,
    },
    {
      name: "Facebook",
      link: "https://web.facebook.com/Transfez.id/",
      logo: fbLogo,
    },
    {
      name: "Youtube",
      link: "https://www.youtube.com/channel/UCetpYhmtJh4JZj_bwaVo5vg",
      logo: ytLogo,
    },
    {
      name: "LinkedIn",
      link: "https://www.linkedin.com/company/transfez/",
      logo: linkedInLogo,
    },
  ]

  const [done, setDone] = useState(false)
  const [already, setAlready] = useState(false)
  const [inputForm, setInputForm] = useState({
    email: "",
  })

  function onSubmit(e) {
    const result = []
    e.preventDefault()
    const headers = {
      Authorization:
        "eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjo0fQ.HzngeSUFSrGEeCk4lL7zHElSUhljNf_0PSK1uB5UWkg",
    }
    axios
      .get(`https://api.webform.transfez.com/api/v1/singapores/`, {
        headers: headers,
      })
      .then(res => {
        for (let i = 0; i < res.data.data.length; i++) {
          if (res.data.data[i].email === inputForm.email) {
            setAlready(true)
            setDone(false)
            result.push(res.data.data[i].email)
          }
        }
        if (result.length === 0) {
          axios
            .post(
              "https://api.webform.transfez.com/api/v1/singapores/",
              {
                email: inputForm.email,
                name: "design-1",
              },
              { headers: headers }
            )
            .then(res => {
              setDone(true)
              setAlready(false)
              setInputForm({
                email: "",
              })
            })
        }
      })
  }

  function onChange(e) {
    let { name, value } = e.target
    const newInputForm = {
      ...inputForm,
      [name]: value,
    }
    setInputForm(newInputForm)
    setDone(false)
    setAlready(false)
  }

  return (
    <>
      <Container
        className="mt-md-5"
        style={status ? { paddingTop: "80px" } : null}
      >
        <ResponsiveRow>
          <Col lg={7}>
            <TitleDiv>
              <SinTitle color="#48a9b5">
                Kirim Uang ke Indonesia <br />
                dari Singapura <FreeBr />
                GRATIS!*
              </SinTitle>
            </TitleDiv>
            <Container>
              <Row style={{ marginBottom: "15%" }}>
                <Col xs={8}>
                  <SinDesc color="#828282" mb="0" fsres="12px">
                    Sekarang bisa kirim uang dengan biaya terjangkau dan bayar
                    tagihan di Indonesia dalam satu aplikasi.
                  </SinDesc>
                </Col>
                <ReleaseCol xs={4} className="d-flex align-items-center">
                  <SinDesc color="#48a9b5" mb="0" ml=".5rem" fsres="12px">
                    RILIS JULI 2021
                  </SinDesc>
                </ReleaseCol>
              </Row>
              <SinDesc
                color="#166F7B"
                mb="1rem"
                ff="GothamBold"
                fsres="12px"
                tares="center"
              >
                Jadilah 300 orang pertama yang mencoba dan dapatkan total 25
                SGD* langsung tanpa diundi. Daftarkan email Anda untuk
                mendapatkan informasi saat aplikasi rilis.
              </SinDesc>
              <FormDiv>
                <form
                  className="row"
                  onSubmit={onSubmit}
                  style={{ marginBottom: "0" }}
                >
                  <FormCol md={7}>
                    <FormControl
                      type="text"
                      name="email"
                      value={inputForm.email}
                      onChange={onChange}
                      placeHolder="Daftarkan alamat email saya"
                      required
                    />
                    <AlertDivRes>
                      <AlertMessage done={done} already={already} />
                    </AlertDivRes>
                  </FormCol>
                  {/* ------------------------------ */}
                  <FormCol md={5}>
                    <ButtonYellow type="submit">Daftar Sekarang</ButtonYellow>
                  </FormCol>
                  <AlertDiv>
                    <AlertMessage done={done} already={already} />
                  </AlertDiv>
                </form>
              </FormDiv>
              <TncDiv>
                <SinDesc color="#828282" ff="GothamBook">
                  *{" "}
                  <LinkTnc to="/transfezsingapura-tnc">
                    <u>
                      <b>Syarat & Ketentuan</b>
                    </u>
                  </LinkTnc>{" "}
                  berlaku
                </SinDesc>
              </TncDiv>
              <SocmedDiv>
                {socmedDatas.map(data => {
                  const { link, logo, name } = data
                  return (
                    <a href={link} target="_blank">
                      <LogoSocmed src={logo} mr=".5rem" alt={name} />
                    </a>
                  )
                })}
              </SocmedDiv>
            </Container>
          </Col>
          <RightCol lg={5}>
            <Fade right>
              <ImageDiv>
                <ImgGif src={baloonSgd} alt="baloon"/>
                <ImageSin src={singLanding1} alt="singapore"/>
                <ImageSinRes src={singRes2} alt="singapore"/>
              </ImageDiv>
            </Fade>
          </RightCol>
        </ResponsiveRow>
      </Container>
    </>
  )
}

export default SingaporeOne

const AlertMessage = ({ done, already }) => {
  return (
    <>
      <div style={{ marginLeft: "15px" }}>
        {done && (
          <small style={{ color: "#48a9b5", fontFamily: "GothamBook" }}>
            <FontAwesomeIcon icon={faCheckCircle} />
            Terima kasih, email Anda sudah terdaftar.
          </small>
        )}
        {already && (
          <small className="text-danger" style={{ fontFamily: "GothamBook" }}>
            <FontAwesomeIcon icon={faTimesCircle} />
            Format Email salah atau Email Anda sudah terdaftar.
          </small>
        )}
      </div>
    </>
  )
}
