import styled from "styled-components"
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import { Link } from "gatsby"

export const TitleDiv = styled(Container)`
  margin-bottom: 20px;
  @media (max-width: 992px) {
    width: 100%;
  }
`

export const SinTitle = styled.h1`
  font-family: "GothamBold";
  color: ${props => props.color};
  font-size: 45px;
  margin-bottom: 0;
  @media (max-width: 576px) {
    font-size: 23px;
  }
`

export const FreeBr = styled.br`
  @media (min-width: 576px) {
    display: none;
  }
`

export const SinDesc = styled.p`
  font-size: ${props => props.fs || "14px"};
  font-family: ${props => props.ff || "GothamMedium"};
  color: ${props => props.color};
  text-align: ${props => props.ta};
  margin-bottom: ${props => props.mb};
  margin-left: ${props => props.ml};
  @media (max-width: 576px) {
    font-size: ${props => props.fsres || "14px"};
    text-align: ${props => props.tares};
    border-left: ${props => props.blres};
    margin-left: ${props => props.mlres};
    margin-right: ${props => props.mrres};
    padding-left: ${props => props.plres};
  }
`

export const ResponsiveRow = styled(Row)`
  @media (max-width: 992px) {
    flex-direction: column-reverse;
  }
`

export const DetailRow = styled(Row)`
  margin-bottom: 15%;
  @media (max-width: 576px) {
    margin-bottom: 0;
  }
`

export const FormCol = styled(Col)`
  padding-left: 0;
  padding-right: 0;
  @media (max-width: 576px) {
    text-align: center;
  }
`

export const ReleaseCol = styled(Col)`
  border-left: 7px solid #ffd27c;
  align-items: center;
  @media (max-width: 576px) {
    border-left: 0px;
    align-items: start;
  }
`

export const FormControl = styled(Form.Control)`
  font-family: "GothamBook";
  width: 95%;
  margin-bottom: 0;
  margin-left: 15px;
  border-color: #828282;
  ::placeholder {
    color: #cacaca;
  }
  @media (max-width: 576px) {
    width: 93%;
    margin-left: auto;
    margin-right: auto;
    ::placeholder {
      text-align: center;
    }
    text-align: center;
  }
`
export const Break = styled.div`
  width: 100%;
`
export const ButtonYellow = styled(Button)`
  padding: 5px 30px;
  background-color: #ffd27c;
  color: #166f7b;
  font-family: "GothamMedium";
  font-size: 16;
  outline: none;
  border-color: #ffd27c;
  border-radius: 10px;
  margin-left: 5%;
  margin-right: auto;
  -webkit-box-shadow: -1px 7px 5px -4px #a0a0a0;
  box-shadow: -1px 7px 5px -4px #a0a0a0;
  :hover,
  :active,
  :focus {
    background-color: #ffd27c;
    border-color: #ffd27c;
    color: #166f7b;
    outline: none;
    filter: brightness(95%);
  }
  @media (max-width: 576px) {
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
    padding: 10px 50px;
  }
`

export const LogoSocmed = styled.img`
  margin-left: ${props => props.ml || ".3rem"};
  margin-right: ${props => props.mr || "1rem"};
  width: 40px;
  height: 40px;
  alt: ${props => props.alt};
`

export const BrResponsive = styled.br`
  @media (min-width: 576px) {
    display: none;
  }
`

export const FormDiv = styled.div`
  width: 100%;
`

export const RightCol = styled(Col)`
  margin-top: -35px;
  z-index: -2;
  @media (max-width: 992px) {
    display: flex;
    justify-content: center;
    margin-top: 0px;
  }
`

export const TncDiv = styled.div`
  margin-top: 10%;
  @media (max-width: 576px) {
    text-align: center;
    margin-top: 7%;
  }
`

export const SocmedDiv = styled.div`
  margin-top: 15%;
  margin-bottom: 5%;
  @media (max-width: 576px) {
    text-align: center;
    margin-top: 7%;
  }
`

export const ImageDiv = styled.div`
  width: 135%;
  @media (max-width: 576px) {
    width: 115%;
    display: none;
  }
`

export const ImageSin = styled.img`
  @media (max-width: 576px) {
    display: none;
  }
`

export const ImageSinRes = styled.img`
  @media (min-width: 576px) {
    display: none;
  }
`

export const ImageDivCenter = styled.div`
  width: 135%;
  @media (min-width: 576px) {
    display: none;
  }
  @media (max-width: 576px) {
    width: 115%;
    margin-top: -10%;
  }
`

export const ImageSinCenter = styled.img`
  @media (max-width: 576px) {
    display: none;
  }
`

export const ImageSinResCenter = styled.img`
  @media (min-width: 576px) {
    display: none;
  }
`

export const AlertDiv = styled.div`
  @media (max-width: 576px) {
    display: none;
  }
`

export const AlertDivRes = styled.div`
  @media (min-width: 576px) {
    display: none;
  }
`

export const LinkTnc = styled(Link)`
  color: #828282;
  :hover {
    color: #828282;
  }
`

export const ImgGif = styled.img`
  width: 60%;
  position: absolute;
  top: 15%;
  left: 0;
`

export const ImgGifRes = styled.img`
  width: 30%;
  position: absolute;
  top: 35%;
  left: 5%;
`